<template>
  <div>
    <h1>Ajouter une formation prégraduée</h1>
    <form-fpregrad :ismeddetail="ismeddetail" :isedit="false" ref="formFpr">
    </form-fpregrad>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFpregrad from "@/components/rh/ui/form/FormFpregrad";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-fpregrad": FormFpregrad },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismeddetail: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    this.$store.commit("fpr/resetFpregrad", idMed);
  },
  computed: {
    fpregradCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fpr.fpregradCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formFpr.$refs.formFpregrad.validate((valid) => {
        if (valid) {
          let fprdata = this.$store.state.fpr.fpregrad;

          // enlever les espaces avant et après dans les string
          trimStringInObj(fprdata);

          // vérifie si la fpregrad existe déjà pour le médecin
          const fprInBD = this.$store.getters["fpr/FpregradInBD"];
          const doublon =
            fprInBD.filter(
              (m) =>
                m[1] == fprdata.id_med &&
                m[2] == fprdata.id_fac &&
                (Number(m[3]) && Number(fprdata.annee)
                  ? m[3] == fprdata.annee
                  : false)
            ).length > 0;
          if (doublon) {
            this.$alert(
              "Ce médecin a déjà un titre enregistré pour la même année dans la même faculté",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("fpr/createFpregrad", fprdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des formations continues
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    fpregradCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.fpregradCreateStatus == 2) {
        // message succès
        this.$message({
          message: "La formation a été ajouté-e à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition du contrat
        this.$router.push(this.routes.toedit);
      } else if (this.fpregradCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.fpregradCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.fpregradCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
