<template>
  <el-main class="rh-form">
    <form-fpregradcreate :routes="fprRoutes" />
  </el-main>
</template>
<script>
import FormFpregradcreate from "@/components/rh/formulaires/FormFpregradcreate";

export default {
  components: { "form-fpregradcreate": FormFpregradcreate },
  computed: {
    fprRoutes() {
      return {
        toedit: {
          name: "fpregradedit",
          params: { idfpr: this.$store.state.fpr.fpregradLastCreatedId },
        },
        tolist: { name: "fpregrads" },
      };
    },
  },
};
</script>
